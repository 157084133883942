<template>
    <b-modal
        v-bind="$attrs"
        size="sm"
        :title="$t('posts.share.title', { postType: post.postTypeToString.toLowerCase()})"
        ok-only
        ok-variant="light"
        :ok-title="$t('buttons.cancel')"
        hide-header-close
        centered
    >
        <div class="text-muted">
            {{ $t('posts.share.description') }}
        </div>

        <b-row
            class="py-3"
            no-gutters
            align-h="between"
        >
            <b-col
                v-for="shareOption in shareOptions"
                :key="shareOption"
                class="mx-1"
            >
                <share-button
                    :post="post"
                    :type="shareOption"
                    variant="outline-primary"
                />
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import ShareButton from '@/components/common/buttons/ShareButton';
import {Post} from '@/models/Post';

export default {
    name: 'ModalSharePost',
    components: {ShareButton},
    props: {
        post: {
            type: Post,
            required: true,
        },
    },
    data: () => ({
        shareOptions: ['copy', 'facebook', 'twitter', 'linkedIn', 'whatsapp', 'mail'],
    }),
};
</script>
