<template>
    <b-btn
        :href="href(postUrl, post)"
        class="btn-circle"
        rel="noopener"
        target="_blank"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <fa
            :icon="icon"
            :size="iconSize"
        />
    </b-btn>
</template>

<script>
export default {
    name: 'LinkShareButton',
    props: {
        icon: {
            type: [String, Array],
            required: true,
        },
        iconSize: {
            type: String,
            required: false,
            default: undefined,
        },
        postUrl: {
            type: String,
            required: true,
        },
        post: {
            type: Object,
            required: true,
        },
        href: {
            type: Function,
            required: true,
        },
    },
};
</script>
