<template>
    <b-btn
        id="copy-share-button"
        class="btn-circle"
        v-bind="$attrs"
        @click="onCopy"
        v-on="$listeners"
    >
        <fa
            v-if="didCopy"
            icon="check"
            :size="iconSize"
        />

        <fa
            v-else
            :icon="icon"
            :size="iconSize"
        />

        <b-tooltip
            ref="tooltip"
            triggers=""
            target="copy-share-button"
            placement="top"
        >
            {{ $t('common.messages.copiedToClipboard', {data: 'URL'}) }}
        </b-tooltip>
    </b-btn>
</template>

<script>
import {detainFeedback, writeToClipboard} from '@/library/helpers';

export default {
    name: 'CopyShareButton',
    props: {
        icon: {
            type: [String, Array],
            required: true,
        },
        iconSize: {
            type: String,
            required: false,
            default: undefined,
        },
        postUrl: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        didCopy: false,
    }),
    methods: {
        async onCopy() {
            await writeToClipboard(this.postUrl);

            this.didCopy = true;

            this.displayTooltip();
        },
        displayTooltip() {
            const tooltip = this.$refs.tooltip;

            if (!tooltip) {
                return;
            }

            tooltip.$emit('open');

            detainFeedback(() => {
                tooltip.$emit('close');

                this.didCopy = false;
            }, 3500);
        },
    },
};
</script>
