<template>
    <b-input-group class="input-group-btn">
        <input-debounced
            class="bg-light pr-0"
            :placeholder="$t('common.words.search')"
            type="search"
            :value="value"
            v-on="$listeners"
        />

        <b-input-group-append>
            <b-button variant="light">
                <fa
                    icon="search"
                    size="1x"
                    class="text-secondary"
                />
            </b-button>
        </b-input-group-append>
    </b-input-group>
</template>

<script>
import InputDebounced from '@/components/common/InputDebounced';

/**
 * A debounced input component with search icon.
 */
export default {
    name: 'InputSearch',
    components: {InputDebounced},
    props: {
        value: {
            type: String,
            required: true,
        },
    },
};
</script>
