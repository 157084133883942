<template>
    <component
        :is="tag"
        v-bind="$attrs"
        v-model="internalValue"
        v-on="listeners"
    />
</template>

<script>
import Vue from 'vue';
import {debounce} from 'lodash';

/**
 * An input component but it's input changes are debounced by a delay (default 250 milliseconds).
 */
export default {
    name: 'InputDebounced',
    props: {
        /**
         * The value.
         */
        value: {
            type: [String, Number, Boolean],
            required: true,
        },
        /**
         * Tag of the component.
         */
        tag: {
            type: [String, Vue, Function],
            required: false,
            default: 'b-input',
        },
        /**
         * Debounce delay.
         */
        delay: {
            type: Number,
            required: false,
            default: 250,
        },
    },
    data: function() {
        return {
            internalValue: this.value,
        };
    },
    computed: {
        /**
         * $listeners without the handler for 'input'.
         */
        listeners() {
            const listeners = Object.assign({}, this.$listeners);
            delete listeners.input;
            return listeners;
        },
    },
    watch: {
        /**
         * Update the internal value when value changes.
         * @param newValue
         */
        value(newValue) {
            if (this.internalValue !== newValue) {
                this.internalValue = newValue;
            }
        },
    },
    created() {
        /**
         * Watcher is added like this because `this` is undefined when declaring a watcher in the `watch` section.
         */
        this.$watch('internalValue', debounce(function(newValue) {
            this.$emit('input', newValue);
        }, this.delay));
    },
};
</script>
