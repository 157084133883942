import Enumeration from './Enumeration';

/**
 * Post privacy enumeration.
 * See: https://github.com/owowagency/monoa-api/blob/f1947c3362302dff75844bf4d90bd7c24f1ea02a/app/Library/Enumerations/PostPrivacy.php
 */
export default class PostPrivacy extends Enumeration {
    /**
     * The post is accessible to every user.
     */
    static PUBLIC = 0;

    /**
     * The post is accessible to every user or users in group that is related
     * to the creator. The related groups are the groups in which the creator
     * of the post has certain roles.
     *
     * Eg:
     * A coach belongs to a group in which it is an admin. All the users within
     * this group will have access to the post.
     */
    static RELATED_TO_CREATOR = 1;

    /**
     * The post is accessible to every user or users in group that is related to the post.
     *
     * Eg:
     * A coach shares the post with a user or group directly.
     */
    static RELATED_TO_POST = 2;

    /**
     * @returns {string} Translation key of the Enum.
     */
    static get translationKey() {
        return 'postPrivacy';
    }

    /**
     * @returns {Object} Enumeration key-value pairs of the Enum.
     */
    static get enumeration() {
        return {
            PUBLIC: this.PUBLIC,
            RELATED_TO_CREATOR: this.RELATED_TO_CREATOR,
            RELATED_TO_POST: this.RELATED_TO_POST,
        };
    };

    /**
     * @returns {string} Translation for the specified key.
     */
    static translateKey(key, suffix = '') {
        const finalSuffix = suffix || 'label';

        return super.translateKey(key, finalSuffix);
    }

    /**
     * @returns {Object} Color of each PostPrivacy.
     */
    static colors = {
        PUBLIC: 'primary',
        RELATED_TO_CREATOR: 'warning',
        RELATED_TO_POST: 'danger',
    };

    /**
     * @returns {string} Color of the specified PostPrivacy.
     */
    static getColor(keyOrValue) {
        const key = typeof keyOrValue === 'string'
            ? keyOrValue
            : this.key(keyOrValue);

        return this.colors[key];
    }
};
