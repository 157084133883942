import {elementThresholdReached} from '@/library/helpers';

export default {
    computed: {
        scrollingElement() {
            return document.scrollingElement;
        },
    },
    watch: {
        async 'collection.query'() {
            this.resetCollection();

            await this.fetchCollectionUntilScrollable();
        },
    },
    methods: {
        /**
         * Clear the collection and reset pagination page to 1.
         */
        resetCollection() {
            this.collection.clearModels();

            this.collection.page(1);

            return this;
        },
        /**
         * Fetch collection from API. Ignore calls if collection is currently loading
         * or already at the last page. Return a boolean that indicates whether
         * the fetching is actually done or not.
         *
         * @return {Promise<Boolean>}
         */
        async fetchCollection() {
            if (this.collection.loading) return false;

            if (this.collection.isLastPage()) return false;

            await this.collection.fetch();

            return true;
        },
        /**
         * Continuously fetch collection until:
         * - The scrolling element (container of the collection) is scrollable.
         * - Or the collection can't be fetched anymore (typically because the
         * last page has been reached).
         *
         * @return {Promise<void>}
         */
        async fetchCollectionUntilScrollable(scrollDirection = 'down') {
            if (!this.scrollingElement instanceof Element) {
                throw new Error('The scrollingElement must be an instance of Element.');
            }

            if (!this.collection.isPaginated()) {
                this.collection.page(1);
            }

            const scrollUp = scrollDirection === 'up' ? true : false;

            let continueFetching = true;

            do {
                await this.$nextTick();

                continueFetching = await this.fetchCollection();
            } while (
                continueFetching
                && elementThresholdReached(this.scrollingElement, 50, scrollUp)
            );
        },
    },
};
