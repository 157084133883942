<template>
    <component
        :is="component"
        v-bind="option"
        v-on="$listeners"
    />
</template>

<script>
import CopyShareButton from '@/components/common/buttons/CopyShareButton';
import LinkShareButton from '@/components/common/buttons/LinkShareButton';

const BASE_URL = process.env.VUE_APP_PUBLIC_URL.replace(/\/+$/, '');

const OPTIONS = {
    copy: {
        icon: ['far', 'copy'],
    },
    facebook: {
        icon: ['fab', 'facebook-f'],
        href(url) {
            return `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        },
    },
    twitter: {
        icon: ['fab', 'twitter'],
        href(url, post) {
            return `http://twitter.com/share?text=${post.title}&url=${url}`;
        },
    },
    linkedIn: {
        icon: ['fab', 'linkedin-in'],
        href(url, post) {
            return `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${post.title}`;
        },
    },
    whatsapp: {
        icon: ['fab', 'whatsapp'],
        iconSize: 'lg',
        href(url, post) {
            return `https://wa.me/?text=${post.title} ${url}`;
        },
    },
    mail: {
        icon: ['far', 'envelope'],
        href(url, post) {
            return `mailto:?subject=${post.title}&body=${url}`;
        },
    },
};

export default {
    name: 'ShareButton',
    components: {
        CopyShareButton, LinkShareButton,
    },
    props: {
        type: {
            type: String,
            validate: (value) => Object.keys(OPTIONS).includes(value),
            required: true,
        },
        post: {
            type: Object,
            required: true,
        },
    },
    data: function() {
        return {
            url: `${BASE_URL}/posts/${this.post.slug}`,
        };
    },
    computed: {
        component() {
            switch (this.type) {
                case 'copy': return CopyShareButton;
                default: return LinkShareButton;
            }
        },
        option() {
            return {
                ...this.$attrs,
                ...OPTIONS[this.type],
                post: this.post,
                postUrl: this.url,
            };
        },
    },
};
</script>
