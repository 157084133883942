import Enumeration from '@/library/enumerations/Enumeration';

/**
 * Post type enumeration.
 * See: https://github.com/owowagency/monoa-api/blob/64f4acfdf84640ffb2a0d20aedcc09ba639aa021/app/Library/Enumerations/PostType.php
 */
class PostType extends Enumeration {
    static VIDEO = 0;
    static AUDIO = 1;
    static ARTICLE = 2;
    static PROGRAM = 3;
    static CHAPTER = 4;
    static PDF = 5;
    static IMAGE = 6;

    /**
     * @returns {string} Translation key of the Enum.
     */
    static get translationKey() {
        return 'postType';
    }

    /**
     * @returns {number[]} Types that are considered "media".
     */
    static get mediaTypes() {
        return [this.AUDIO, this.PDF, this.VIDEO, this.IMAGE];
    }

    /**
     * @returns {Object} Enumeration key-value pairs of the Enum.
     */
    static get enumeration() {
        return {
            VIDEO: this.VIDEO,
            AUDIO: this.AUDIO,
            ARTICLE: this.ARTICLE,
            PROGRAM: this.PROGRAM,
            CHAPTER: this.CHAPTER,
            PDF: this.PDF,
            IMAGE: this.IMAGE,
        };
    };
}

export default PostType;
