import {get, omit} from 'lodash';
import PostType from '@/library/enumerations/PostType';
import {applyAttributesMixin} from '@/models/vue-mc/helpers';
import Vue from 'vue';
import Model from './vue-mc/Model';
import Image from '@/library/Image';
import Collection from './vue-mc/Collection';
import PostPrivacy from '@/library/enumerations/PostPrivacy';
import {SupportedLocales} from '@/library/SupportedLanguages';
import {User} from '@/models/User';

export class Post extends Model {
    /**
     * When this flag is set to true, the API will return translatable attributes for all locales, e.g:
     * { title: { en: 'Post title', nl: 'Post titel'} }
     * @type {boolean}
     */
    withTranslations = true

    get isPublic() {
        return this.privacy === PostPrivacy.PUBLIC;
    }

    get postTypeToString() {
        return PostType.$t(this.type);
    }

    /**
     * Return translated string of the instance's privacy.
     *
     * @return {String}
     */
    get privacyToString() {
        return PostPrivacy.$t(this.privacy);
    }

    /**
     * Get the thumbnail of the post, as Image instance.
     *
     * @return {Image}
     */
    get thumbnail() {
        let thumbnail = this.get('thumbnail');

        if (typeof thumbnail === 'string') return thumbnail;

        thumbnail = thumbnail ? thumbnail['200'] : thumbnail;

        const url = thumbnail
            || this.videoThumbnail
            || this.filesThumbnail
            || require('@/assets/images/post-thumbnail-placeholder.svg');

        return new Image({
            srcSet: {200: url},
            description: this.title,
            width: 128,
            height: 72,
        });
    }

    get filesThumbnail() {
        return Object.values(this.files)
            // Files can be an object of arrays when translated.
            .flatMap(f => Array.isArray(f) ? f : [f])
            .filter(f => !!f['200'])
            .map(f => f['200'])
            [0];
    }

    get videoThumbnail() {
        if (!this.video || typeof this.video !== 'object') {
            return null;
        }

        if (this.video.thumbnail) {
            return this.video.thumbnail;
        }

        let locale = this.locale;

        if (!this.video.hasOwnProperty(locale)) {
            locale = (process.env.VUE_APP_API_URL || '').toLowerCase();

            if (!this.video.hasOwnProperty(locale)) {
                locale = Object.keys(this.video)[0];
            }
        }

        return get(this.video, `${locale}.thumbnail`);
    }

    getFetchQuery() {
        const query = super.getFetchQuery();

        if (this.withTranslations) {
            query.withTranslations = '1';
        }

        return query;
    }

    getSaveQuery() {
        const query = super.getFetchQuery();

        if (this.withTranslations) {
            query.withTranslations = '1';
        }

        return query;
    }

    getTranslatedAttribute(attribute, preferredLocale = 'en') {
        const value = this.get(attribute);

        // If the attribute is a string, just return the string
        if (typeof value === 'string') {
            return value;
        }

        // If attribute is an object, it's a dictionary of locale codes and translated values.
        if (typeof value === 'object' && value != null) {
            // Check if the preferred locale exists, return it
            if (preferredLocale in value) {
                return value[preferredLocale];
            }

            // Find the first known locale in the object and return it's value.
            const localeKeys = Object.keys(value).filter(locale => SupportedLocales.includes(locale));
            if (localeKeys.length >= 1) {
                return value[localeKeys[0]];
            }
        }

        return undefined;
    }

    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            id: null,
            creator: null,
            title: {},
            body: {},
            privacy: PostPrivacy.RELATED_TO_POST,
            type: '',
            locale: 'nl',
            files: {},
            video: {},
            thumbnail: null,
        };
    }

    /**
     * Options of this model.
     *
     * @return {object}
     */
    options() {
        return {
            endpoint: 'posts',
        };
    }

    /**
     * @returns {Object} The data to send to the server when saving.
     */
    getSaveData() {
        let data = super.getSaveData();

        // Never send video to API, we will directly upload to S3.
        const propertiesToOmit = ['video'];

        if (data.type === PostType.VIDEO) {
            // Don't send the files property to the API if the post is a video.
            propertiesToOmit.push('files');
        }

        // Only send string files with the request.
        if (data.files) {
            Object.entries(data.files)
                .forEach(([key, files]) => {
                    data.files[key] = files.filter(f => typeof f === 'string');

                    if (data.files[key].length === 0) {
                        propertiesToOmit.push(`files.${key}`);
                    }
                });
        }

        data = omit(data, ...propertiesToOmit);

        return data;
    }

    assign(attrs) {
        if (attrs.video === null) {
            attrs.video = {};
        }

        if (Array.isArray(attrs.body)) {
            attrs.body = {};
        }

        super.assign(attrs);
    }

    transformations() {
        return {
            creator: User,
        };
    }
}

export const Posts = applyAttributesMixin(class Posts extends Collection {
    /**
     * Called after construction. Sets default properties of a collection.
     */
    boot() {
        super.boot();

        Vue.set(this, 'types', []);

        Vue.set(this, 'privacies', []);
    }

    /**
     * @returns {Object} Query parameters that will be appended to the `fetch` URL.
     */
    getFetchQuery() {
        let query = super.getFetchQuery();

        if (this.types.length > 0) {
            query.types = this.types;
        }

        if (this.privacies.length > 0) {
            query.privacies = this.privacies;
        }

        return query;
    }

    /**
     * @returns {Object} The data to send to the server when saving.
     */
    getSaveData() {
        let data = {
            post_types: this.types,
            post_ids: this.models.map(post => post.id),
        };

        return data;
    }

    /**
     * Options of this collection.
     *
     * @return {object}
     */
    options() {
        return {
            model: Post,
        };
    }
});
