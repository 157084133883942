<template>
    <b-row>
        <b-col
            v-for="(item, index) of items"
            :key="index"
            class="mb-3"
            :cols="cols"
            :xs="xs"
            :sm="sm"
            :md="md"
            :lg="lg"
            :xl="xl"
        >
            <slot :item="item" />
        </b-col>
    </b-row>
</template>

<script>
/**
 * A list of columns, by default 4 columns per row, this can be changed by passing values to cols and the props xs - xl.
 * There is no default styling for each item, it provides a slot for each item in {@link items}.
 */
export default {
    name: 'GridList',
    props: {
        /**
         * Default column size.
         */
        cols: {
            type: [Number, String],
            default: 3,
        },
        /**
         * Column size for `xs` screens.
         */
        xs: {
            type: [Number, String],
            default: null,
        },
        /**
         * Column size for `sm` screens.
         */
        sm: {
            type: [Number, String],
            default: null,
        },
        /**
         * Column size for `md` screens.
         */
        md: {
            type: [Number, String],
            default: null,
        },
        /**
         * Column size for `lg` screens.
         */
        lg: {
            type: [Number, String],
            default: null,
        },
        /**
         * Column size for `xl` screens.
         */
        xl: {
            type: [Number, String],
            default: null,
        },
        /**
         * The list of items to display.
         * TODO: Once API is ready, we could pass a Collection instead of a list of items.
         */
        items: {
            type: Array,
            required: true,
        },
    },
};
</script>
