<template>
    <div class="media-card rounded overflow-hidden">
        <div
            class="header flex-grow-1 text-light px-3 pb-3"
            :style="{backgroundImage}"
        >
            <slot />

            <div
                v-if="showShare && post.isPublic"
                class="share-overlay"
            >
                <modal-share-post
                    :id="`modal-share-post-${post.id}`"
                    :post="post"
                />

                <b-button
                    variant="white"
                    @click.prevent="onShare($event, post)"
                >
                    {{ $t('common.actions.share') }}
                </b-button>
            </div>

            <div
                class="d-flex align-items-end text-shadow"
                style="height: 3rem;"
            >
                <div>
                    {{ post.title }}

                    <div
                        v-if="post.creator"
                        class="small"
                    >
                        {{ $t('common.createdBy', {name: post.creator.name}) }}
                    </div>
                </div>

                <small
                    v-if="isVideo"
                    class="ml-auto"
                >
                    {{ formattedDuration }}
                </small>
            </div>
        </div>

        <div class="footer d-flex align-items-center p-3">
            <div class="d-flex flex-column flex-grow-1">
                <b-form-group
                    :label="post.getTranslation('createdAt')"
                    class="plaintext"
                >
                    {{ formattedUploadDate }}
                </b-form-group>
            </div>

            <div
                v-if="!hidePrivacy"
                class="d-flex flex-column flex-grow-1"
            >
                <b-form-group
                    :label="post.getTranslation('privacy')"
                    class="plaintext text-primary"
                >
                    {{ post.privacyToString }}
                </b-form-group>
            </div>

            <div class="d-flex flex-column flex-grow-1">
                <b-form-group
                    :label="post.getTranslation('views')"
                    class="plaintext"
                >
                    {{ post.views }}
                </b-form-group>
            </div>

            <slot
                name="details"
                :media="post"
            />
        </div>
    </div>
</template>

<script>
import ModalSharePost from '@/components/posts/modals/ModalSharePost';
import {Post} from '@/models/Post';
import PostType from '@/library/enumerations/PostType';

export default {
    name: 'MediaCard',
    components: {ModalSharePost},
    props: {
        hidePrivacy: {
            type: Boolean,
            default: false,
        },
        post: {
            type: Post,
            required: true,
        },
        showShare: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        backgroundImage() {
            return `url(${this._.get(this.post.thumbnail, 'srcSet.200')})`;
        },
        formattedDuration() {
            if (!this.isVideo) {
                return '';
            }

            let duration = parseFloat(this._.get(this.post, 'video.duration')) || 0;

            duration = this.$moment.unix(duration).format('mm:ss');

            return duration;
        },
        formattedUploadDate() {
            return this.$moment(this.post.createdAt).format('DD-MM-YYYY');
        },
        isVideo() {
            return this.post.type === PostType.VIDEO;
        },
    },
    methods: {
        onShare(event, post) {
            event.stopPropagation();
            this.$bvModal.show(`modal-share-post-${post.id}`);
        },
    },
};
</script>

<style lang="scss">
.media-card {
    .header {
        position: relative;
        // Keep the thumbnail 16:9, reduce with content height
        padding-top: percentage(9/16) - 14%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        box-shadow: inset 0 -65px 25px -20px rgba($black, .5);

        .share-overlay {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 100%;
            height: 100%;
            padding: map-get($spacers, 2);
            background-color: rgba(0, 0, 0, .4);
            opacity: 0;
            transition: opacity .3s ease;
        }

        &:hover {
            .share-overlay {
                opacity: 1;
            }
        }
    }
}
</style>
